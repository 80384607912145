import React from "react";
import Layout from "../components/Layout";
import Head from "../components/Head";
import Monogram from "../components/Monogram";
import Header from "../components/Header";
import BlogExcerpt from "../components/BlogExcerpt";
import Pagination from "../components/Pagination";
import MarkdownContent from "../components/MarkdownContent";
import * as classNames from "./ArchivePage.module.css";
import { ArchivePageContext } from "../util/page-types";

const ArchivePage: React.FC<{
  data: null;
  pageContext: ArchivePageContext;
}> = ({ pageContext }) => {
  const blurb = pageContext.intro.body;
  return (
    <Layout>
      <Head title="Archive" description={blurb} />
      <Header title="Blog Post Archive" />
      <MarkdownContent className={classNames.disclaimer} html={blurb} />
      <MarkdownContent brief={true}>
        {pageContext.posts.map((post) => (
          <BlogExcerpt post={post} key={post.id} />
        ))}
      </MarkdownContent>
      <Monogram footer={true} />
      <Pagination
        numberOfPages={pageContext.numPages}
        currentPage={pageContext.currentPage}
      />
    </Layout>
  );
};

export default ArchivePage;
