import React, { FC, ReactNode } from "react";
import * as classNames from "./Pagination.module.css";
import { Link } from "gatsby";
import Arrow, { ArrowDirection } from "./Arrow";

export type PaginationProps = {
  currentPage: number;
  numberOfPages: number;
  pageSpread?: number;
};

const DEFAULT_PAGE_SPREAD = 3;
function url(n: number): string {
  return n === 1 ? `/archive` : `/archive/${n.toString(10)}`;
}

/**
 * Generates a paginator for the blog archive.
 *
 * The `pageSpread` determines how wide the paginator is; it's the
 * number of links on either side of the center link, so you'll end
 * up with a list of the following sizes:
 *
 * | Spread | Count |
 * | ------ | ----- |
 * | 1      | 3     |
 * | 2      | 5     |
 * | 3      | 7     |
 *
 * Et cetera.
 */
const Pagination: FC<PaginationProps> = ({
  currentPage,
  numberOfPages,
  pageSpread = DEFAULT_PAGE_SPREAD,
}) => {
  // Don't render anything if there's only one page
  if (numberOfPages === 1) {
    return null;
  }

  const previous = currentPage - 1;
  const next = currentPage + 1;

  let centerPage = currentPage;
  let minPage = centerPage - pageSpread;
  if (minPage < 1) {
    minPage = 1;
    centerPage = 1 + pageSpread;
  }
  let maxPage = centerPage + pageSpread;
  if (maxPage > numberOfPages) {
    maxPage = numberOfPages;
    minPage = maxPage - 2 * pageSpread;
    if (minPage < 1) {
      minPage = 1;
    }
  }
  const pages: ReactNode[] = [];
  for (let i = minPage; i <= maxPage; i += 1) {
    const pageNum = `${i.toString(10)}`;
    pages.push(
      i === currentPage ? (
        <span key={i} className={classNames.page}>
          {pageNum}
        </span>
      ) : (
        <Link key={i} to={url(i)} className={classNames.page}>
          {pageNum}
        </Link>
      )
    );
  }

  return (
    <div className={classNames.root}>
      {previous > 0 && (
        <Link className={classNames.previous} to={url(previous)}>
          <Arrow direction={ArrowDirection.LEFT} />
          <span className={classNames.text}>Previous</span>
        </Link>
      )}
      <div className={classNames.pages}>{pages}</div>
      {next < numberOfPages && (
        <Link className={classNames.next} to={url(next)}>
          <span className={classNames.text}>Next</span>
          <Arrow direction={ArrowDirection.RIGHT} />
        </Link>
      )}
    </div>
  );
};
export default Pagination;
